import React, { Suspense } from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Banners from './components/banners/Banners';
import TopBanner from './components/top_banner/TopBanner';
import ShopByInnovatorSlider from './components/shop_by_innovator_slider/ShopByInnovatorSlider';

const Instagram = React.lazy(() => import('./components/instagram/Instagram'));
const StickyCategories = React.lazy(() => import('./components/sticky_categories/StickyCategories'));

document.addEventListener('DOMContentLoaded', () => {
  const elementId = 'react-banners-component';
  const bannersElement = document.getElementById(elementId);
  const data = JSON.parse(document.querySelector(`script[data-dom-id="${elementId}"]`).textContent);
  const { banners } = data;
  if (banners.length > 0) {
    hydrateRoot(
      bannersElement,
      <Banners banners={banners} />,
    );
  } else {
    const topBannerElement = document.getElementById('top-banner');
    const { url } = topBannerElement.dataset;
    createRoot(
      topBannerElement,
    ).render(
      <TopBanner url={url} />,
    );
  }

  const shopByInnovatorsElement = document.getElementById('react-shop-by-innovator');
  const { innovators } = JSON.parse(document.querySelector('script[data-dom-id="react-shop-by-innovator"]').textContent);
  hydrateRoot(
    shopByInnovatorsElement,
    <ShopByInnovatorSlider innovators={innovators} />,
  );

  const instagramElement = document.getElementById('instagram');
  const { token } = instagramElement.dataset;
  createRoot(
    instagramElement,
  ).render(
    <Suspense fallback="">
      <Instagram token={token} />
    </Suspense>,
  );

  createRoot(
    document.getElementById('react-sticky-categories'),
  ).render(
    <Suspense fallback="">
      <Provider store={window.store}>
        <StickyCategories />
      </Provider>
    </Suspense>,
  );
});
