import React from 'react';
import styles from './InnovatorCard.module.sass';

function InnovatorCard(props) {
  const { innovator } = props;

  const {
    name, title, description, url, image_url: imageUrl,
  } = innovator;

  return (
    <a className={styles['innovator-card']} href={url}>
      <div className={styles.image}>
        <img src={imageUrl} alt={title} />
      </div>
      <div className={styles.title}>{name}</div>
      <div className={styles.description}>{title}</div>
      <div className={styles.description}>{description}</div>
    </a>
  );
}

export default InnovatorCard;
