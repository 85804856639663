import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './ShopByInnovatorSlider.module.sass';
import './SlickOverrides.sass';
import ClickableDiv from '../../../ui_kit/clickable_div/ClickableDiv';
import arrowLeftMobileIcon from '../../../../images/pages/the_lounge/arrow-left-mobile.svg';
import arrowLeftDesktopIcon from '../../../../images/pages/the_lounge/arrow-left-desktop.svg';
import InnovatorCard from './innovator_card/InnovatorCard';

const MOBILE_SLIDES_COUNT = 2;
const DESKTOP_SLIDES_COUNT = 4;

function ShopByInnovatorSlider({ innovators }) {
  const mobileSliderRef = useRef(null);
  const desktopSliderRef = useRef(null);

  const onMobileLeftArrowClick = () => {
    mobileSliderRef.current.slickPrev();
  };

  const onMobileRightArrowClick = () => {
    mobileSliderRef.current.slickNext();
  };

  const onDesktopLeftArrowClick = () => {
    desktopSliderRef.current.slickPrev();
  };

  const onDesktopRightArrowClick = () => {
    desktopSliderRef.current.slickNext();
  };

  return (
    <div className={`${styles['shop-by-innovator-slider']} shop-by-innovator-slider`}>
      {innovators.length > MOBILE_SLIDES_COUNT && (
        <ClickableDiv className={`${styles.arrow} ${styles.left} ${styles.mobile}`} onClick={onMobileLeftArrowClick}>
          <img src={arrowLeftMobileIcon} alt="arrow left" />
        </ClickableDiv>
      )}
      <Slider
        slidesToShow={MOBILE_SLIDES_COUNT}
        slidesToScroll={MOBILE_SLIDES_COUNT}
        className={`${styles.slider} ${styles.mobile}`}
        arrows={false}
        ref={(ref) => { mobileSliderRef.current = ref; }}
      >
        {innovators.map((innovator) => (
          <InnovatorCard
            key={innovator.id}
            innovator={innovator}
          />
        ))}
      </Slider>
      {innovators.length > MOBILE_SLIDES_COUNT && (
        <ClickableDiv className={`${styles.arrow} ${styles.right} ${styles.mobile}`} onClick={onMobileRightArrowClick}>
          <img src={arrowLeftMobileIcon} alt="arrow right" />
        </ClickableDiv>
      )}
      {innovators.length > DESKTOP_SLIDES_COUNT && (
        <ClickableDiv className={`${styles.arrow} ${styles.left} ${styles.desktop}`} onClick={onDesktopLeftArrowClick}>
          <img src={arrowLeftDesktopIcon} alt="arrow left" />
        </ClickableDiv>
      )}
      <Slider
        slidesToShow={DESKTOP_SLIDES_COUNT}
        slidesToScroll={DESKTOP_SLIDES_COUNT}
        className={`${styles.slider} ${styles.desktop}`}
        arrows={false}
        ref={(ref) => { desktopSliderRef.current = ref; }}
      >
        {innovators.map((innovator) => (
          <InnovatorCard
            key={innovator.id}
            innovator={innovator}
          />
        ))}
      </Slider>
      {innovators.length > DESKTOP_SLIDES_COUNT && (
        <ClickableDiv className={`${styles.arrow} ${styles.right} ${styles.desktop}`} onClick={onDesktopRightArrowClick}>
          <img src={arrowLeftDesktopIcon} alt="arrow right" />
        </ClickableDiv>
      )}
    </div>
  );
}

export default ShopByInnovatorSlider;
